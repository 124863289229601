import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import VideoPlayer from "react-video-js-player";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

import '../style/testimonial.scss';

// import required modules
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import video1 from "../content/testi1.mp4"
import video2 from "../content/testi2.mp4"
import video3 from "../content/testi3.mp4"
import poster1 from "../content/poster1.png"
import poster2 from "../content/poster2.png"
import poster3 from "../content/poster3.png"




export default function Testimonial() {
  return (
    <>
    <div className="testimonials" id="testimonial">
      <h1 className="heading">What our clients say <i class="fa-solid fa-mug-hot"></i></h1>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={ true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
      >
        <SwiperSlide>
        <video poster={poster2}  controls  muted>
        <source src={video2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
        </SwiperSlide>
        <SwiperSlide>
        <video poster={poster1}controls  muted>
        <source src={video1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        </SwiperSlide>
        <SwiperSlide>
        <video poster={poster3} controls  muted>
        <source src={video3} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
    </>

  );
}
