import React from 'react'
import "../style/footer.scss"
function Footer() {
  return (

    <div className="footer">
        <div className="footer-line"></div>
        <div className="footer-container">

        <div className="footer-links">
            <div className="smedia">

            <a href="https://www.facebook.com/profile.php?id=61559806435904" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-facebook-f"></i></a>
            </div>
             <div className="smedia">
             <a href="https://wa.me/+919755638324" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-whatsapp"></i></a>
            </div>
             <div className="smedia">
             <a href="https://www.instagram.com/hitchmedia7?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-instagram"></i></a>
            </div>
             <div className="smedia">
             <a href="/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-snapchat" ></i></a>
            </div>
             <div className="smedia">
             <a href="https://www.linkedin.com/in/hitch-media-29a866309/" target="_blank" rel="noopener noreferrer"><i class="fa-brands fa-linkedin-in"></i></a>
            </div>


        </div>
        <div className="footer-copyright">
            <p> &copy; hitchmedia 2024. all rights reserved</p>
        </div>
        </div>

    </div>
  )
}

export default Footer