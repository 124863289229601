import React, { useState } from 'react';
import "./header.scss"
import logo from "../content/logo.png"
function Header() {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div className="header">
        <div className="navbar">
            <div className="logo">
              <img src={logo} alt="logo"/>
            </div>
          
                <ul className="menu-list">
                  <li><a href="/">Home</a></li>
                  <li><a href="#testimonial">Videos</a></li>
                  <li><a href="#contact">Contact</a></li>
                </ul>
                <div>
                  <a  href="mailto:Official.hitchmedia@gmail.com"className="sendmail-btn">
                    Send a Mail.
                  </a>
                </div>
                <div className="menu-dropdown">
                  <div onClick={toggleDropdown}>

                <i class="fa-solid fa-bars"></i>
                  </div>

      {dropdownVisible && (
        <div className="dropdown-show">
          <a href="/" onClick={toggleDropdown}>Home</a>
          <a href="#testimonial" onClick={toggleDropdown}>Videos</a>
          <a href="#contact" onClick={toggleDropdown}>Contact </a>
          <a href="mailto:Official.hitchmedia@gmail.com" onClick={toggleDropdown}>Send a mail.</a>

        </div>
      )}
    </div>
          </div>
        </div>
  );
}

export default Header;
