import "../style/home.scss"
import React, { useEffect, useRef } from 'react';
import homimg1 from "../content/homimg1.jpg"
import homimg3 from "../content/homimg3.jpg"
import homimg4 from "../content/homimg4.jpg"


import home1 from "../content/hom1.mp4"
import home2 from "../content/hom2.mp4"

import home3 from "../content/hom3.mp4"
import home4 from "../content/hom4.mp4"
import home5 from "../content/hom5.mp4"
import home6 from "../content/hom6.mp4"


function Home() {
    const videoRef = useRef(null);
    useEffect(() => {
        // Ensure video element is loaded before attempting to autoplay
        if (videoRef.current) {
          videoRef.current.play().catch(error => {
            console.error('Autoplay failed:', error);
          });
        }
      }, []);
  return (
   <div className="home">
    <div className="flex-section">
     <div className="left-section">
        <h1 className="logoname">Hitch<br/> Media</h1>
        <p>Welcome to our Digital Marketing Agency! <br/>
        Your partner in business development. <br/> 
        We specialize in tailoring targeted campaigns,<br/> optimising SEO and crafting engaging content <br/> to attract and convert high quality leads.</p>
        <a className="ls-btn1" href="tel:+919755638324">Call us now <i class="fa-solid fa-arrow-right-long"></i></a>
        <a className="ls-btn2" href="#testimonial">Testimonials</a>


     </div>
     <div className="right-section">
        <div className="container">

        <div className="video-container">

     <video ref={videoRef} autoPlay  muted loop>
        <source src={home1} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
        </div>
        <div className="video-container">
        <video ref={videoRef} autoPlay  muted loop>
        <source src={home2} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

   </div>
   <div className="video-container">

<video ref={videoRef} autoPlay  muted loop>
   <source src={home3} type="video/mp4" />
   Your browser does not support the video tag.
 </video>
   </div>

   </div>
        <div className="container">
    
   <div className="video-container">
<img src={homimg1} alt="founder" />
   </div>
   <div className="video-container">

<video ref={videoRef} autoPlay  muted loop>
   <source src={home6} type="video/mp4" />
   Your browser does not support the video tag.
 </video>
   </div>
   <div className="video-container">

   <img src={homimg4} alt="founder" />

   </div>
   </div>
   <div className="container">

   <div className="video-container">

<video ref={videoRef} autoPlay  muted loop>
   <source src={home5} type="video/mp4" />
   Your browser does not support the video tag.
 </video>
   </div>
   <div className="video-container">
<img src={homimg3} alt="founder" />
    
   </div>
   <div className="video-container">

<video ref={videoRef} autoPlay  muted loop>
   <source src={home4} type="video/mp4" />
   Your browser does not support the video tag.
 </video>
   </div>
</div>
     </div>
    </div>  
   </div>
  );
}

export default Home;
