import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import "../style/contact.scss"
function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_r1hnaw3', 'template_wef5rkc', form.current, {
        publicKey: 'yQ4j8PTnRWDWCALfa',
      })
      //  .sendForm("service_r0vdn1v","template_f6y1nar", form.current, {
      //   publicKey: 'oba6AGHXFgDoI0o3N',
      // })
      .then(
        () => {
          alert("message sent");
          if (form.current) {
            form.current.reset();
          }
        },
        (error) => {
          alert("message not sent");
          
        },
      );
  };

  return (
    <div className="contact-container" id='contact'>
        <div className="contact-info">

        <h1 className="heading">Feel free to contact us <i class="fa-solid fa-envelope"></i></h1>
        <div className="contact-links">
          <div className="link">
            <a href='/'>
              <i class="fa-solid fa-location-dot"></i>
            </a>
            <h4>Address :</h4>
            <p>Dehradun</p>

          </div>
          <div className="link">
            <a href='mailto:official.hitchmedia@gmail.com'>
              <i class="fa-solid fa-at"></i>
            </a>
            <h4>Email:</h4>
            <p>official.hitchmedia@gmail.com</p>
            
          </div>
          <div className="link">
            <a href="tel:+919755638324">
              <i class="fa-solid fa-phone"></i>
            </a>
            <h4>Phone:</h4>
            <p>+919755638324</p>
          </div>
         
        </div>
        <div className="contact form">
        <form ref={form} onSubmit={sendEmail} className='contact-inputs'>
      <input type="text" name="from_name" required placeholder='Full Name'/>
      <input type="tel" name="from_mobile" placeholder='contact number (optional)'/>

      <input type="email" name="from_email" required placeholder="What's your email" />
      <textarea name="message" required  placeholder=' Message'/>
      <input type="submit" value="Send Message" />
    </form >
        </div>
        </div>

    </div>
   
  );
}

export default Contact;