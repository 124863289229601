import './App.css';
import Contact from './pages/Contact';
import Footer from './pages/Footer';
import Header from './pages/header';
import Home from './pages/home';
import Testimonial from './pages/Testimonial';
import Slider from './pages/Testimonial';



function App() {
  return (
    <div className="App">
      <Header></Header>
      <Home></Home>
      <Testimonial></Testimonial>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
}


export default App;
